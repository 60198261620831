import { ArnConnectionStatus } from '@arianee/arn-client';
import customEventBus from '@common/scripts/libs/eventBus';
import api from '@utils/api';
import { ArnHttpClientFactory } from '@arianee/arn-client';
import BaseService from '../base/baseService';
export default class AppService extends BaseService {
    constructor() {
        super();
        this.error = false;
        if (window.wconnectUrl) {
            this.init();
        }
    }
    init() {
        document.addEventListener('connected', (e) => {
            var _a;
            console.log('arn-connect status:', 'connected');
            customEventBus.emit('wallet:arn-button:status', { enabled: true });
            if ((_a = e.detail) === null || _a === void 0 ? void 0 : _a.isConnected()) {
                this.error = false;
                const searchParams = new URLSearchParams(window.location.search);
                if (searchParams.has('wallet-connected')) {
                    window.location.reload();
                }
                else {
                    searchParams.append('wallet-connected', 'true');
                    window.location.search = searchParams.toString();
                }
            }
        });
        document.addEventListener('disconnected', (e) => {
            console.log('arn-connect status:', 'disconnected');
            if (!this.error) {
                window.location.reload();
            }
        });
        document.addEventListener('error', (e) => {
            console.log('arn-connect status:', 'error');
            console.log('ArnConnect error', e);
            this.error = true;
            // disconnect wallet in case of "signing" refusal
            window.arnClient.auth.currentContext.disconnect();
            customEventBus.emit('wallet:wallet-connect:failed', {});
            customEventBus.emit('wallet:arn-button:status', { enabled: true });
        });
        document.addEventListener('connecting', (e) => {
            console.log('arn-connect status:', 'connecting');
            customEventBus.emit('wallet:arn-button:status', { enabled: false });
        });
        const clientFactory = new ArnHttpClientFactory();
        clientFactory.createFromUrl(window.wconnectUrl).then(() => {
            var _a;
            customEventBus.emit('arnClient:ready', { ready: true });
            api.setSessionAttribute('walletConnect', !!((_a = window.arnClient) === null || _a === void 0 ? void 0 : _a.auth.isConnected()));
            window.arnClient.auth.currentContext$.subscribe(async (authContext) => {
                authContext === null || authContext === void 0 ? void 0 : authContext.status$.subscribe(async (status) => {
                    switch (status === null || status === void 0 ? void 0 : status.connectionStatus) {
                        case ArnConnectionStatus.authenticated:
                            api.setSessionAttribute('walletConnect', true);
                            break;
                        case ArnConnectionStatus.disconnected:
                            await api.setSessionAttribute('walletConnect', false);
                            break;
                        case ArnConnectionStatus.connecting:
                            if (window.arnClient.auth.isConnected()) {
                                this.error = true;
                                await window.arnClient.auth.currentContext.disconnect();
                            }
                            break;
                        case ArnConnectionStatus.signing:
                            break;
                    }
                });
            });
        });
    }
}
