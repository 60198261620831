// definizione window.mqObj
window.mqObj = {
    xs: 375,
    sm: 600,
    md: 1024,
    lg: 1200,
    xl: 1440,
};
export const ResolutionHandler = () => {
    window.isDesktop = () => {
        return window.innerWidth >= window.mqObj.md;
    };
    window.isMobile = () => {
        return window.innerWidth < window.mqObj.md;
    };
};
/**
 *
 * @param {String} [max = null] - The name of the max-width breakpoint
 * @param {String} [min = null] - The name of the min-width breakpoint
 * @return {boolean}
 */
export const match = (max = null, min = null) => {
    if (!max && !min)
        return false;
    let maxString = '';
    let minString = '';
    if (max) {
        maxString = `(max-width: ${parseFloat(window.mqObj[max]) - 1}px)${min ? ' and ' : ''}`;
    }
    if (min) {
        minString = `(min-width: ${window.mqObj[min]}px)`;
    }
    return window.matchMedia(`${maxString}${minString}`).matches;
};
