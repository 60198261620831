import { appPostMessage } from '@utils/appUtils';
import BaseService from '../base/baseService';
export default class AppService extends BaseService {
    constructor() {
        super();
        if (window.appmode) {
            this.monitorClick();
        }
    }
    monitorClick() {
        document.addEventListener('click', function (e) {
            const nativeTarget = e.target;
            let target = nativeTarget;
            //check simple data-analytics event
            if (!(target === null || target === void 0 ? void 0 : target.classList.contains('noapplink'))) {
                return;
            }
            let message = target.getAttribute('data-postmessage');
            if (message) {
                appPostMessage('message');
            }
            e.preventDefault();
            e.stopPropagation();
        });
    }
}
