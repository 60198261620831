import axios from 'axios';
//import chiamata from '@common/mocks/NFTs/chiamataVera.json';
import qs from 'qs';
import formutils from './formutils';
export const baseUrl = window.apiroot;
const dateTimeFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
function reviver(key, value) {
    if (typeof value === 'string' && dateTimeFormat.test(value)) {
        return new Date(value);
    }
    return value;
}
/**
 * Adds a valid CSRF token to any parameters to be sent to a controller.
 *
 * @param params The API call parameters
 * @returns the same parameters enriched with a valid CSRF token
 */
async function withCsrf(params) {
    const csrf = await api.csrf();
    params[csrf.tokenName] = csrf.token;
    return params;
}
/**
 * Intercept if a request is for a Business Manager Pipeline
 * @param config
 * @returns
 */
function isBmPipeline(config) {
    const BmController = ['BmClothing', 'BmTranslations', 'BmClientService'];
    let controller = config.url.split('-')[0].replace('/', '');
    if (BmController.includes(controller)) {
        return '/on/demandware.store/Sites-Site/default/';
    }
    return config.baseURL;
}
const instance = axios.create({
    baseURL: baseUrl,
    headers: {
        // 'content-type': 'application/json',
        'content-type': 'application/x-www-form-urlencoded',
        'x-requested-with': 'XMLHttpRequest',
        accept: 'application/json',
        device: window.appmode ? 'smartphone' : undefined,
    },
    transformResponse: [
        function transformResponse(data) {
            if (typeof data === 'string') {
                try {
                    data = JSON.parse(data, reviver);
                }
                catch (e) {
                    /* Ignore */
                }
            }
            return data;
        },
    ],
    validateStatus: () => true,
});
let numberOfAjaxCAllPending = 0;
instance.interceptors.request.use((config) => {
    config.baseURL = isBmPipeline(config);
    numberOfAjaxCAllPending++;
    document.body.setAttribute('aria-busy', 'true');
    config.paramsSerializer = (params) => qs.stringify(params, {
        arrayFormat: 'repeat',
    });
    return config;
});
instance.interceptors.response.use((response) => {
    numberOfAjaxCAllPending--;
    if (numberOfAjaxCAllPending === 0) {
        document.body.removeAttribute('aria-busy');
    }
    return response;
}, (error) => {
    numberOfAjaxCAllPending--;
    if (numberOfAjaxCAllPending === 0) {
        document.body.removeAttribute('aria-busy');
    }
    return Promise.reject(error);
});
const api = {
    getProduct: (pid) => {
        return instance
            .get(`/ProductApi-Product`, {
            params: {
                pid: pid,
                cachekill: new Date(),
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    getProductTile: (pid, selectedColor) => {
        return instance
            .get(`/Api-ProductTile`, {
            params: {
                pid: pid,
                selectedColor: selectedColor,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    variantPrice: (pid) => {
        return instance
            .get(`/ProductApi-VariantPrice`, {
            params: {
                pid: pid,
            },
        })
            .then(({ data }) => {
            return data.price;
        });
    },
    getProductTiles: (pids, cancelToken) => {
        return instance
            .get(`/Api-ProductTiles`, {
            params: {
                pid: pids,
            },
            cancelToken: cancelToken === null || cancelToken === void 0 ? void 0 : cancelToken.token,
        })
            .then(({ data }) => {
            return data.products || [];
        })
            .catch((thrown) => {
            if (axios.isCancel(thrown)) {
                console.log('getProductTiles Request canceled');
            }
            else {
                console.error(thrown);
            }
        });
    },
    getProductTilesFromCategory: (categoryID) => {
        return instance
            .get('/Api-ProductTilesCategory', {
            params: {
                cgid: categoryID,
            },
        })
            .then(({ data }) => {
            return data.products || [];
        })
            .catch((thrown) => {
            if (axios.isCancel(thrown)) {
                console.log('getProductTilesFromCategory Request canceled');
            }
            else {
                console.error(thrown);
            }
        });
    },
    getForm: (type, initialValues, clearForm) => {
        return instance
            .get(`/FormApi-GetForm`, {
            params: {
                form: type,
                msg: window.msgdebug ? '42' : undefined,
                clear: clearForm,
            },
        })
            .then(({ data }) => {
            const form = formutils.flattenForm(data.form);
            if (initialValues) {
                formutils.fillInitialValues(form, initialValues);
            }
            return form;
        });
    },
    getFormAndSubmit: (form, def, formname) => {
        const converted = formutils.objToForm(form, def);
        converted.formname = formname;
        return instance.post(`/FormApi-GetForm`, qs.stringify(converted)).then(({ data }) => {
            return formutils.flattenForm(data.form);
        });
    },
    validateForm: (form, def, formname) => {
        const converted = formutils.objToForm(form, def);
        converted.formname = formname;
        return instance.post(`/FormApi-ValidateForm`, qs.stringify(converted)).then(({ data }) => {
            return formutils.flattenForm(data.form);
        });
    },
    getUserInfo: async () => {
        return instance.get('/AccountApi-UserInfo').then(({ data: { user } }) => user);
    },
    login: async (form, redirectToPdp, rurl) => {
        return instance
            .post(`/Account-Login${redirectToPdp ? '?redirectToPdp=' + redirectToPdp : rurl ? '?rurl=' + rurl : ''}`, qs.stringify(await withCsrf(form)))
            .then(({ data }) => {
            return data;
        });
    },
    checkLogin: async (form) => {
        return instance.post(`/Account-CheckLogin`, qs.stringify(await withCsrf(form))).then(({ data }) => {
            return data;
        });
    },
    trebaselegheLogin: async (form) => {
        return instance.post('/BookAnAppointmentTrebaseleghe-Login', qs.stringify(await withCsrf(form))).then(({ data }) => {
            return data;
        });
    },
    baaGetEmployee: async () => {
        return instance.get('/BookAnAppointmentTrebaseleghe-GetEmployee').then(({ data }) => {
            return data;
        });
    },
    baaTrebaGetSlots: async () => {
        return instance.get('/BookAnAppointmentTrebaseleghe-GetSlots').then(({ data }) => {
            return data;
        });
    },
    baaTrebaLogout: async () => {
        return instance.get('/BookAnAppointmentTrebaseleghe-Logout').then(({ data }) => {
            return data;
        });
    },
    baaTrebaGetBookingDetail: async (bookingCode, guestEmail) => {
        return instance.get('/BookAnAppointmentTrebaseleghe-GetBookingDetail', { params: { bookingCode: bookingCode, email: guestEmail } }).then(({ data }) => {
            return data;
        });
    },
    baaTrebaBookingSubmit: async (form) => {
        return instance.post('/BookAnAppointmentTrebaseleghe-Submit', qs.stringify(await withCsrf(form))).then(({ data }) => {
            return data;
        });
    },
    baaTrebaBookingSubmitGuest: async (form) => {
        return instance.post('/BookAnAppointmentTrebaseleghe-SubmitGuest', qs.stringify(await withCsrf(form))).then(({ data }) => {
            return data;
        });
    },
    baaTrebaBookingEdit: async (form) => {
        return instance.post('/BookAnAppointmentTrebaseleghe-Edit', qs.stringify(await withCsrf(form))).then(({ data }) => {
            return data;
        });
    },
    baaInvitationSubmit: async (form) => {
        return instance.post('/BookAnAppointmentTrebaseleghe-SubmitInvitation', qs.stringify(await withCsrf(form))).then(({ data }) => {
            return data;
        });
    },
    baaCancelAppointment: async (id) => {
        return instance.post('/BookAnAppointmentTrebaseleghe-CancelAppointment', qs.stringify({ id: id })).then(({ data }) => {
            return data;
        });
    },
    registerClothing: async (form) => {
        return instance.post(`/Account-SubmitEmployeeRegistration`, qs.stringify(await withCsrf(form))).then(({ data }) => {
            return data;
        });
    },
    sendClothingDoubleOptIn: async (email) => {
        return instance.get('/Account-SendClothingDoubleOptIn', {
            params: {
                email: email,
            },
        });
    },
    sendLoginOnBehalfRegistrationEmail: async (email, subscriptionLink) => {
        return instance.get('/Account-SendLoginOnBehalfRegistrationEmail', {
            params: {
                email: email,
                subscriptionLink: subscriptionLink,
            },
        });
    },
    logout: async () => {
        return instance.post(`/Account-Logout`).then(({ data }) => {
            return data;
        });
    },
    getAccountHeader: async () => {
        return instance.get(`/Account-Header`).then(({ data }) => {
            return data;
        });
    },
    forgotPasswordSendMail: async (email) => {
        return instance.post(`/AccountApi-PasswordResetRequest`, qs.stringify({ loginEmail: email })).then(({ data }) => {
            return data;
        });
    },
    forgotPasswordChange: async (form, def, token) => {
        return instance.post(`/AccountApi-SaveNewPassword?Token=${token}`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    socialDisconnect: async (social) => {
        return instance.post(`/AccountApi-SocialDisconnect`, qs.stringify(await withCsrf({ social: social }))).then(({ data }) => {
            return data;
        });
    },
    changePassword: async (form, def) => {
        return instance.post(`/AccountApi-SaveChangedPassword`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    checkNewsletterEmail: async (email) => {
        return instance
            .get('/NewsletterApi-ShowNewslettersModal', {
            params: {
                customerEmail: email,
            },
        })
            .then((resp) => ({
            success: !resp.data.error,
        }));
    },
    submitNewsletter: async (form, def) => {
        return instance.post('/NewsletterApi-SubmitNewsletters', qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then((resp) => ({
            success: !resp.data.error,
        }));
    },
    newsletteroptin: async (token) => {
        return instance
            .get(`/NewsletterApi-Optin`, {
            params: {
                token: token,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    accountSaveMarketingPrefs: async (form, def) => {
        return instance.post(`/AccountApi-SaveMarketingPrefs`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    saveBankInfo: async (form, def) => {
        return instance.post(`/ReturnOrderApi-SaveBankInfo`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    savePaymentInstrument: async (form, def) => {
        return instance.post(`/PaymentInstruments-SavePayment`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    paymentLogos: async () => {
        return instance.get('/CheckoutApi-PaymentLogos').then((resp) => resp.data.paymentmethods);
    },
    getPaymentLogosFiltered: async () => {
        return instance.get('/CheckoutAppApi-FilteredPaymentLogos').then((resp) => resp.data.paymentmethods);
    },
    register: async (form, def, redirectToPdp) => {
        return instance
            .post(`/Account-SubmitRegistration${redirectToPdp ? '?redirectToPdp=' + redirectToPdp : ''}`, qs.stringify(await withCsrf(formutils.objToForm(form, def))))
            .then(({ data }) => {
            return data;
        });
    },
    loginOnBehalfRegistration: async (form, def) => {
        return instance.post(`/Account-LoginOnBehalfRegistration`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    reportingEventStart: async (event) => {
        return instance.get(`/ReportingEvent-Start`, {
            params: event,
        });
    },
    saveProfile: async (form, def) => {
        return instance.post(`/AccountApi-SaveProfile`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    changeEmail: async (form, def) => {
        return instance.post(`/AccountApi-ChangeEmail`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    savePrivacy: async (form, def) => {
        return instance.post(`/AccountApi-Privacy`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    getWishlist: async (type, excludeCache) => {
        return instance
            .get('/WishlistApi-Get', {
            params: {
                type: type || undefined,
                excludeCache: excludeCache || undefined,
            },
        })
            .then((resp) => resp.data.wishlist);
    },
    /** @deprecated tutte le wishlist in un colpo solo è la cosa peggiore in termini di performances, chiamate il get con il tipo  */
    // getAllWishlists: async (): Promise<models.AllWishlists> => {
    //   return instance.get('/WishlistApi-GetAll').then((resp) => resp.data);
    // },
    shareWishlist: async (form, def) => {
        return instance.post(`/WishlistApi-ShareWishlist`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    replaceWishlistProduct: async (listId, oldProductId, newProductId, recipeId, removeDuplicate) => {
        return instance
            .post('/WishlistApi-ReplaceProduct', qs.stringify({
            listId: listId,
            oldProductId: oldProductId,
            newProductId: newProductId,
            recipeId: recipeId,
            removeDuplicate: removeDuplicate,
        }))
            .then(({ data }) => {
            return data.replacedList;
        });
    },
    GeneratePublicWishlist: async (listId) => {
        return instance
            .get('/WishlistApi-GeneratePublicWishlist', {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                listId: listId,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    getJsonPublicWishlist: async (listId, key) => {
        return instance
            .get('/WishlistApi-JsonPublicWishlist', {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                listId: listId,
                key: key,
            },
        })
            .then(({ data }) => data);
    },
    ShareSingleProductWishlist: async (productId, email, msg, listId, recipeId) => {
        return instance
            .post('/WishlistApi-ShareSingleProduct', qs.stringify({
            productId: productId,
            email: email,
            message: msg,
            listId: listId,
            recipeId: recipeId,
        }))
            .then(({ data }) => data);
    },
    getCountryLanguageOptions: async () => {
        return instance.get('/AccountApi-CountryLanguage').then(({ data }) => {
            return data;
        });
    },
    saveCountryLanguage: async (locale) => {
        return instance
            .post('/AccountApi-SaveCountryLanguage', qs.stringify({
            locale,
        }))
            .then(({ data }) => {
            return data;
        });
    },
    addToWishlist: async (pid, recipeid) => {
        return instance
            .post('/WishlistApi-AddProduct', qs.stringify({
            pid: pid,
            recipeid: recipeid,
        }))
            .then(({ data }) => data);
    },
    removeFromWishlist: async (pid) => {
        return instance
            .get('/WishlistApi-RemoveProduct', {
            params: {
                pid,
            },
        })
            .then(({ data }) => data);
    },
    removeItemFromWishlist: async (uuid, type) => {
        return instance
            .get('/WishlistApi-RemoveItem', {
            params: {
                uuid: uuid,
                type: type,
            },
        })
            .then(({ data }) => data);
    },
    moveFromWishlistToCart: async (uuid, type, cardOptions) => {
        return instance
            .post('/WishlistApi-MoveToCart', Object.assign({}, cardOptions), {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
            params: {
                uuid: uuid,
                type: type,
            },
        })
            .then(({ data }) => data);
    },
    getOrders: async (page = null, itemsPerPage = null, offset = null) => {
        return instance
            .get('/AccountApi-OrderHistory', {
            params: {
                page,
                itemsPerPage,
                offset,
            },
        })
            .then((resp) => resp.data.orders);
    },
    getOrdersNext: async (page = null, itemsPerPage = null, offset = null) => {
        return instance
            .get('/AccountApi-OrderHistory', {
            params: {
                page,
                itemsPerPage,
                offset,
                separateGC: true,
            },
        })
            .then((resp) => {
            return {
                orders: resp.data.orders,
                orderTotal: resp.data.total,
            };
        });
    },
    getAftercares: async (page = null, itemsPerPage = null, offset = null) => {
        return instance
            .get('/AccountApi-AftercareHistory', {
            params: {
                page,
                itemsPerPage,
                offset,
            },
        })
            .then((resp) => resp.data.aftercares);
    },
    getAftercaresPaged: async (page = null, itemsPerPage = null, offset = null) => {
        return instance
            .get('/AccountApi-AftercareHistory', {
            params: {
                page,
                itemsPerPage,
                offset,
            },
        })
            .then((resp) => resp.data);
    },
    getOrderDetail: async (id, token, separateGiftCard) => {
        return instance
            .get(`/OrderApi-Details`, {
            params: {
                orderID: id,
                token: token || undefined,
                separateGiftCard: separateGiftCard || undefined,
            },
        })
            .then((resp) => resp.data.order);
    },
    getAftercareCase: async (id) => {
        return instance
            .get(`/OrderApi-GetAftercareCase`, {
            params: {
                orderID: id,
            },
        })
            .then((resp) => resp.data.result);
    },
    MyFeedHighlights: async () => {
        return instance.get('AccountApi-MyFeedHighlights').then((resp) => resp.data);
    },
    checkPaybylinkStatus: async (id, token) => {
        return instance
            .get(`/Paybylink-CheckPayment`, {
            params: {
                orderID: id,
                token: token || undefined,
            },
        })
            .then((resp) => resp.data);
    },
    checkPaymentStatus: async () => {
        return instance.get(`/CheckoutApi-CheckPaymentStatus`, {}).then((resp) => resp.data);
    },
    getReturns: async () => {
        return instance.get('/AccountApi-ReturnHistory').then((resp) => resp.data.returns);
    },
    getReturnsNext: async (page = null, itemsPerPage = null, offset = null) => {
        return instance
            .get('/AccountApi-ReturnHistory', {
            params: {
                page,
                itemsPerPage,
                offset,
            },
        })
            .then((resp) => {
            return {
                returns: resp.data.returns,
                returnTotal: resp.data.total,
            };
        });
    },
    getReturnDetail: async (id, token) => {
        return instance
            .get('/ReturnOrderApi-Details', {
            params: {
                orderID: id,
                token: token || undefined,
            },
        })
            .then((resp) => resp.data.returncase);
    },
    uploadFile: (fieldname, file, onUploadProgress) => {
        let formData = new FormData();
        formData.append(fieldname, file);
        return instance
            .post('/FileUpload-Post', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        })
            .then(({ data }) => data)
            .catch((e) => {
            console.info(e.response);
        });
    },
    removeFile: (fieldname, file) => {
        return instance
            .post('FileUpload-RemoveFile', qs.stringify({
            field: fieldname,
            file: file,
        }))
            .then(({ data }) => data);
    },
    openReturn: async (openReturnOrderNumber, openReturnOrderEmail, exchange) => {
        return instance
            .post('ReturnOrderApi-OpenReturn', qs.stringify({
            openReturnOrderNumber,
            openReturnOrderEmail,
            exchange,
        }))
            .then(({ data }) => data);
    },
    trackOrderOrReturn: async (form, def) => {
        return instance.post('OrderApi-TrackOrderOrReturn', qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => data);
    },
    getReturnReasons: async () => {
        return instance.get('ReturnOrderApi-ReturnReasons').then(({ data }) => data.returnReasons);
    },
    createReturnRequest: async (returnRequest) => {
        return instance
            .post('ReturnOrderApi-Process', returnRequest, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
            validateStatus: () => true, // also return details in case of http500
        })
            .then(({ data }) => data);
    },
    expressGooglepayAuthorize: async (data) => {
        return instance
            .post('CheckoutExpressGooglePay-GooglepayAuthorize', data, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
            validateStatus: () => true, // also return details in case of http500
        })
            .then(({ data }) => data.data);
    },
    expressGooglepayDataChange: async (data) => {
        return instance
            .post('CheckoutExpressGooglePay-GooglepayDataChange', data, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
            validateStatus: () => true, // also return details in case of http500
        })
            .then(({ data }) => data.data);
    },
    expressAmazonPayShopperDetails: async (data) => {
        return instance
            .post('CheckoutExpressAmazonPay-ShopperDetails', data, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
            validateStatus: () => true, // also return details in case of http500
        })
            .then(({ data }) => data.data);
    },
    expressSubmitPayment: async (form, def) => {
        return instance.post(`/CheckoutServices-SubmitExpress`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    createExchangeRequest: async (returnRequest) => {
        return instance
            .post('ExchangeOrderApi-CreateExchange', returnRequest, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
            validateStatus: () => true, // also return details in case of http500
        })
            .then(({ data }) => data);
    },
    cancelExchangeRequest: async () => {
        return instance.post(`/ExchangeOrderApi-Cancel`, qs.stringify(await withCsrf({}))).then(({ data }) => {
            return data;
        });
    },
    getAfterCares: async () => {
        return instance.get('/AccountApi-OrderHistory').then((resp) => resp.data.orders);
    },
    csrf: async () => {
        const { data } = await instance.post('CSRF-Generate');
        return data.csrf;
    },
    getMiniCart: async () => {
        return instance.get('CartApi-MiniCart').then(({ data }) => {
            return data;
        });
    },
    getFullCart: async () => {
        return instance.get('CartApi-Cart').then(({ data }) => {
            return data;
        });
    },
    emailMyCart: async (form, def) => {
        return instance.post(`/CartApi-MailMyCart`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    expressAddToCart: (pid) => {
        return instance.get(`/CartApi-ExpressAddToCart`, { params: { pid: pid } }).then(({ data }) => {
            return data;
        });
    },
    expressCheckoutStart: async () => {
        return instance.post('CheckoutExpress-Start').then(({ data }) => {
            return data;
        });
    },
    getCheckoutData: async (removeGCs) => {
        return instance
            .get('CheckoutApi-CheckoutData', {
            params: {
                removeGCs,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    getPBLCheckoutData: async (orderID, orderToken, removeGCs) => {
        return instance
            .get('CheckoutApi-PBLCheckoutData', {
            params: {
                orderID,
                orderToken,
                removeGCs,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    getHolidayCalendar: async () => {
        return instance.get('CheckoutApi-Holidays').then(({ data }) => {
            return data.data;
        });
    },
    getAdyenConfig: async () => {
        return instance.get('CheckoutApi-AdyenConfig').then(({ data }) => {
            return data;
        });
    },
    getAddressBook: async () => {
        return instance.get('AddressApi-AddressBook').then(({ data }) => {
            return data;
        });
    },
    getProfile: async () => {
        return instance.get('AccountApi-Profile').then(({ data }) => {
            return data;
        });
    },
    getMbmConfig: async () => {
        return instance.get('MbmBe-GetConfig').then(({ data }) => {
            return data;
        });
    },
    getAllCampaigns: async () => {
        return instance.get('BmClothing-GetAllCampaigns').then(({ data }) => {
            return data.campaigns;
        });
    },
    createCampaign: async (campaign) => {
        return instance
            .post('BmClothing-Create', { campaign: campaign }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    updateCampaign: async (campaign) => {
        return instance
            .post('BmClothing-Update', { campaign: campaign }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    deleteCampaign: async (campaignid) => {
        return instance
            .post('BmClothing-Delete', { campaignid: campaignid }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    CustomerExistByEmail: async (email) => {
        return instance
            .get('AccountApi-GetCustomerByEmail', {
            params: {
                email: email,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    saveMeasmerizeToken: async (token) => {
        return instance
            .post(`AccountApi-SaveMeasmerizeToken`, { token: token }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => data);
    },
    getAccountModel: async () => {
        return instance.get('AccountApi-AccountModel').then(({ data }) => {
            return data;
        });
    },
    addToBag: (pid, quantity, replaced, cardOptions) => {
        return instance
            .post(`/Cart-AddProduct`, qs.stringify(Object.assign({ pid: pid, quantity: quantity || 1, options: [], replaced: replaced }, cardOptions)))
            .then(({ data }) => {
            return data;
        });
    },
    addToBagReplaceProduct: (pid, uuid) => {
        return instance
            .post(`/CartApi-ReplaceProduct`, qs.stringify({
            pid: pid,
            uuid: uuid,
        }))
            .then(({ data }) => {
            return data;
        });
    },
    cartChangeQuantity: (uuid, quantity) => {
        return instance
            .post(`/CartApi-ChangeQuantity`, qs.stringify({
            uuid: uuid,
            quantity: quantity,
        }))
            .then(({ data }) => {
            return data;
        });
    },
    mbmAddToBag: (pid, recipeid, uuid) => {
        return instance
            .post(`/MbmApi-AddToCart`, qs.stringify({
            pid: pid,
            recipeid: recipeid,
            uuid: uuid || undefined,
            edit: !!uuid || undefined,
        }))
            .then(({ data }) => {
            return data;
        });
    },
    mbmAvailableStores: () => {
        return instance.get(`/MbmApi-AvailableStores`).then(({ data }) => data);
    },
    mbmGetShareConfigurationUrl: (recipeid, size) => {
        return instance.get(`/MbmApi-GetShareConfigurationUrl`, { params: { recipeid: recipeid, size: size } }).then(({ data }) => data);
    },
    sendQRCodeMbmEmail: (mbmValues) => {
        return instance
            .post('/MbmApi-SendQRCodeEmail', mbmValues, {
            headers: {
                'Content-type': 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    yamatoTest: async (zipcode) => {
        return instance.get(`/YamatoApi-Test`, { params: { zipcode: zipcode } }).then(({ data }) => data);
    },
    addPromoCode: async (code) => {
        return instance.get(`/Cart-AddCoupon`, { params: await withCsrf({ couponCode: code }) }).then(({ data }) => data);
    },
    promocodeRemove: async (uuid) => {
        return instance.get(`/Cart-RemoveCouponLineItem`, { params: await withCsrf({ uuid: uuid }) }).then(({ data }) => data);
    },
    removeFromBag: (pid, uuid) => {
        return instance.post(`/CartApi-RemoveProductLineItem`, qs.stringify({ pid, uuid })).then(({ data }) => {
            return data;
        });
    },
    removeMultipleProductFromBag: (lineItems) => {
        return instance
            .post(`/CartApi-RemoveProductLineItems`, { lineItems: lineItems }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    moveToWishlist: (uuid) => {
        return instance.post(`/CartApi-MoveToWishlist`, qs.stringify({ uuid })).then(({ data }) => data);
    },
    checkoutUpdateShippingAddress: async (form, def) => {
        return instance.post(`/CheckoutApi-UpdateShippingAddress`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    checkoutSubmitPackaging: async (form, def) => {
        return instance.post(`/CheckoutApi-SubmitPackaging`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    checkoutSubmitShipping: async (form, def) => {
        return instance.post(`/CheckoutShippingServices-SubmitShipping`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    updateShipmentMethod: async (shippingMethod) => {
        return instance.get('/CheckoutShippingServices-UpdateShipmentMethod', { params: { shippingMethod } }).then(({ data }) => {
            return data.order;
        });
    },
    checkoutSubmitShippingPickup: async (form, def) => {
        return instance.post(`/CheckoutShippingServicesPickup-SubmitShipping`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    checkoutSubmitPickupStore: async (store) => {
        return instance.post(`/CheckoutShippingServicesPickup-SelectStore`, qs.stringify(await withCsrf({ store: store.ID }))).then(({ data }) => {
            return data;
        });
    },
    checkoutSubmitPayment: async (form, def, order) => {
        return instance
            .post(`/CheckoutServices-SubmitPayment`, qs.stringify(await withCsrf(Object.assign(Object.assign({}, formutils.objToForm(form, def)), { orderID: order === null || order === void 0 ? void 0 : order.orderNumber, orderToken: order === null || order === void 0 ? void 0 : order.token }))))
            .then(({ data }) => {
            return data;
        });
    },
    checkoutSubmitBillingAddress: async (form, def) => {
        return instance.post(`/CheckoutServices-SubmitBillingAddress`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    checkoutSaveMail: async (email) => {
        return instance
            .post(`/CheckoutApi-SaveMail`, qs.stringify(await withCsrf({
            email: email,
        })))
            .then(({ data }) => {
            return data;
        });
    },
    checkoutRemoveMail: async () => {
        return instance.post(`/CheckoutApi-RemoveMail`).then(({ data }) => {
            return data;
        });
    },
    checkoutPlaceOrder: async (order) => {
        return instance.post(`/CheckoutServices-PlaceOrder`, qs.stringify(await withCsrf({ orderID: order === null || order === void 0 ? void 0 : order.orderNumber, orderToken: order === null || order === void 0 ? void 0 : order.token }))).then(({ data }) => {
            return data;
        });
    },
    paypalExpressPlaceOrder: async (orderNumber, token, shopperEmail) => {
        return instance
            .post(`/CheckoutExpress-PaypalExpressPlaceOrder`, qs.stringify(await withCsrf({
            orderNumber: orderNumber,
            token: token,
            shopperEmail: shopperEmail,
        })))
            .then(({ data }) => {
            return data;
        });
    },
    saveAddress: async (form, def, id) => {
        return instance
            .post('/AddressApi-SaveAddress', qs.stringify(await withCsrf(formutils.objToForm(form, def))), {
            params: {
                addressId: id,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    deleteAddress: async (id, isDefault) => {
        return instance
            .get(`/Address-DeleteAddress`, {
            params: {
                addressId: id,
                isDefault: isDefault,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    editAddress: (id, initialValues) => {
        return instance
            .get(`/AddressApi-EditAddress`, {
            params: {
                addressId: id,
            },
        })
            .then(({ data }) => {
            const form = formutils.flattenForm(data.form);
            if (initialValues) {
                formutils.fillInitialValues(form, initialValues);
            }
            return form;
        });
    },
    checkBoutiqueAvailability: (pid, country, excludemode, geo) => {
        return instance
            .get(`/BoutiqueApi-CheckAvailability`, {
            params: {
                productid: pid,
                country,
                exclude: !!excludemode,
                lat: geo === null || geo === void 0 ? void 0 : geo.lat,
                lng: geo === null || geo === void 0 ? void 0 : geo.lng,
            },
        })
            .then(({ data }) => {
            return data;
        })
            .catch((thrown) => {
            console.error(thrown);
        });
    },
    getPaymentMethods: (order) => {
        return instance
            .get(`/Adyen-GetPaymentMethodsModel`, {
            params: {
                orderID: order === null || order === void 0 ? void 0 : order.orderNumber,
                orderToken: order === null || order === void 0 ? void 0 : order.token,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    adyenPaymentFromComponent: (data, paymentMethod) => {
        return instance.post(`/Adyen-PaymentFromComponent`, qs.stringify({ data: JSON.stringify(data), paymentMethod: paymentMethod })).then(({ data }) => {
            return data;
        });
    },
    adyenPaymentDetails: (data) => {
        return instance.post(`/Adyen-PaymentDetails`, qs.stringify({ data: JSON.stringify(data) })).then(({ data }) => {
            return data.data;
        });
    },
    reserveInBoutique: async (form, def) => {
        return instance.post(`/BoutiqueApi-ReserveSubmit`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    bookAnAppointmentSlots: async (store, type, reserve, reason) => {
        return instance
            .get(`/BookAnAppointmentApi-Slots`, {
            params: {
                store: store,
                type: type,
                reserve: reserve,
                reason: reason,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    bookAnAppointmentStores: (country, mbm, appointmentType, reason) => {
        return instance.get('/BookAnAppointmentApi-Stores', { params: { country, mbm, appointmentType, reason } }).then(({ data }) => {
            return data;
        });
    },
    bookAnAppointmentCollections: async (storeid) => {
        return instance
            .get(`/BookAnAppointmentApi-Collections`, {
            params: {
                ID: storeid,
            },
        })
            .then(({ data }) => {
            return data.data;
        });
    },
    bookAnAppointmentSubmit: async (form, def) => {
        return instance.post(`/BookAnAppointmentApi-Submit`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data.data;
        });
    },
    bookAnAppointmentEdit: async (form, def) => {
        return instance.post(`/BookAnAppointmentApi-Edit`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data.data;
        });
    },
    bookAnAppointmentTrack: async (form, def) => {
        return instance.post(`/BookAnAppointmentApi-Track`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    bookAnAppointmentCancel: async (code) => {
        return instance.post(`/BookAnAppointmentApi-CancelAppointment`, qs.stringify(await withCsrf({ code: code }))).then(({ data }) => {
            return data;
        });
    },
    bookAnAppointmentGet: async (code) => {
        return instance
            .get(`/BookAnAppointmentApi-Get`, {
            params: {
                code: code,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    bookAnAppointmentList: async (page = null, itemsPerPage = null, offset = null, past = null) => {
        return instance
            .get(`/BookAnAppointmentApi-List`, {
            params: {
                page,
                itemsPerPage,
                offset,
                past: past,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    notifymeSubmit: async (form, def) => {
        return instance.post(`/NotifyMe-NotifySubmit`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    mbmNotifymeSubmit: async (form, def) => {
        return instance.post(`/MbmApi-NotifySubmit`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    notifymeNewsletterSubscription: async (form, def) => {
        return instance.post(`/NotifyMe-NewsletterSubscription`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    notifymeWishlist: async (form, def) => {
        return instance.post(`/NotifyMe-NotifySubmitWishlist`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    saveInvoiceRequest: async (form, def) => {
        return instance.post(`/CheckoutApi-SaveInvoiceRequest`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    contactUsSubmit: async (form, def) => {
        return instance.post(`/CustomerCareApi-SubmitContactForm`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    deleteAccount: async (customerId) => {
        return instance.post(`/CustomerCareApi-SubmitRemoveCustomer`, qs.stringify({ customerId })).then(({ data }) => {
            return data;
        });
    },
    contactUsFaqs: async (id) => {
        return instance
            .get(`/CustomerCareApi-ContactusFaqs`, {
            params: {
                id: id,
            },
        })
            .then(({ data }) => {
            return data.items;
        });
    },
    GetContactsByCountry: async (countryCode) => {
        return instance
            .post(`/ClientServiceApi-GetContactsByCountry`, { countryCode: countryCode }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    GetAllCountriesForContacts: async () => {
        return instance.get(`/ClientServiceApi-GetAllCountriesForContacts`).then(({ data }) => {
            return data;
        });
    },
    getTfnForAllCountries: async () => {
        return instance.get(`/CustomerCareApi-GetTelephoneForAllCountries`).then(({ data }) => data);
    },
    aftercareRequest: async () => {
        return instance.get(`/CustomerCareApi-AftercareRequest`).then(({ data }) => {
            return data.data;
        });
    },
    aftercareStep1: async (form, def) => {
        return instance.post(`/CustomerCareApi-AftercareStep1`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    aftercareStep2: async (form, def) => {
        return instance.post(`/CustomerCareApi-AftercareStep2`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    aftercareStep3: async (form, def) => {
        return instance.post(`/CustomerCareApi-AftercareStep3`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    aftercareSubmit: async () => {
        return instance.post(`/CustomerCareApi-AftercareSubmit`).then(({ data }) => {
            return data;
        });
    },
    getBoutiqueCountries: () => {
        return instance.get(`/BoutiqueApi-Countries`).then(({ data }) => {
            return data.data;
        });
    },
    getClickAndReserveConfig: () => {
        return instance.get(`/BoutiqueApi-GetClickAndReserveConfiguration`).then(({ data }) => {
            return data;
        });
    },
    getBoutiqueCountriesAndCities: () => {
        return instance.get(`/BoutiqueApi-CountriesAndCities`).then(({ data }) => {
            return data.data;
        });
    },
    // slotid=einstein-product-recommendations
    getSlotConfig: (slotid, cancelToken) => {
        return instance
            .get(`/__SYSTEM__Slot-Request`, {
            params: {
                slotid: slotid,
                context: 'global',
            },
            cancelToken: cancelToken.token,
        })
            .then((data) => data)
            .catch((thrown) => {
            if (axios.isCancel(thrown)) {
                console.log('getSlotConfig Request canceled');
            }
            else {
                console.error(thrown);
            }
        });
    },
    getSlotContent: (slotuuid, pid) => {
        let payload = {
            auid: '',
            scid: slotuuid,
        };
        for (let j = 0; j < pid.length; j++) {
            payload['pid' + j] = pid[j];
        }
        return instance.post(`/CQRecomm-Start`, qs.stringify(payload)).then((data) => data.data);
    },
    searchProducts: (params) => {
        var _a;
        let queryparams = {
            q: params.q || undefined,
            cgid: params.id || undefined,
            srule: params.selectedSort || undefined,
            sz: params.pageSize || undefined,
            start: params.start || undefined,
            type: params.type || undefined,
            shelf: params.shelf || undefined,
        };
        if (params.selectedFilters) {
            params.selectedFilters
                .filter((value) => value === null || value === void 0 ? void 0 : value.values.length)
                .forEach((currentValue, index) => {
                //price-range refinements
                if (currentValue.id === 'price') {
                    const minMax = currentValue.values[0].split('-');
                    queryparams.pmin = minMax[0];
                    queryparams.pmax = minMax[1];
                    return true;
                }
                //default refinements
                queryparams[`prefn${index + 1}`] = currentValue.id;
                queryparams[`prefv${index + 1}`] = currentValue.values.join('|');
            });
        }
        // console.log('API slug is ' + params.slug);
        let url = '/SearchApi-Search';
        if (params.slug && !params.id) {
            url = `https://${window.location.host}${params.slug}`;
            queryparams.json = true;
        }
        if (window.environment != 'production' && window.location.host.includes('demandware.net')) {
            queryparams.isobject = true;
            return instance
                .get(url, { params: queryparams, cancelToken: params === null || params === void 0 ? void 0 : params.cancelToken })
                .then(({ data }) => {
                if (data.data) {
                    return data.data;
                }
                let stripped = data.substring(data.indexOf('{\n  "action"'), data.lastIndexOf('<!-- dwMarker="dw-object-page'));
                console.log('stripped: ' + stripped);
                return JSON.parse(stripped).data;
            })
                .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                    console.log('searchProducts Request canceled');
                }
                else {
                    console.error(thrown);
                }
            });
        }
        return instance
            .get(url, { params: queryparams, cancelToken: params === null || params === void 0 ? void 0 : params.cancelToken, signal: (_a = params.abortController) === null || _a === void 0 ? void 0 : _a.signal })
            .then(({ data }) => data.data)
            .catch((thrown) => {
            if (axios.isCancel(thrown)) {
                console.log('searchProducts Request canceled');
            }
            else {
                console.error(thrown);
            }
        });
    },
    getSuggestions: (withEditorial) => {
        return instance
            .get('/SearchApi-GetSuggestions', {
            params: {
                withEditorial: withEditorial,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    getContentAsset: (cid) => {
        return instance
            .get('/SearchApi-GetContent', {
            params: {
                cid: cid,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    getSizeChart: (pid) => {
        return instance
            .get(`/ProductApi-SizeChart`, {
            params: {
                pid: pid,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    getInternationalSizes: (pid) => {
        return instance
            .get(`/ProductApi-GetInternationalSizes`, {
            params: {
                pid: pid,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    getShippingAndReturns: async (type) => {
        return instance
            .get(`/ProductApi-ShippingAndReturns`, {
            params: {
                type: type,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    getStoresForPickup: () => {
        return instance.get('/StoresApi-PickupStores').then(({ data }) => {
            return data;
        });
    },
    getCountriesWithBAA: (personalizationRoom, liveBoutique) => {
        return instance
            .get('/StoresApi-BAACountries', {
            params: {
                personalizationRoom: personalizationRoom,
                liveBoutique: liveBoutique,
            },
        })
            .then(({ data }) => {
            return data.data;
        });
    },
    getStoresForAppointment: (country) => {
        return instance.get('/StoresApi-StoresForAppointment', { params: { country } }).then(({ data }) => {
            return { Store: data.Store, PersonalizationStore: data.PersonalizationStore };
        });
    },
    getLiveBoutiqueStoresForAppointment: (country) => {
        return instance.get('/StoresApi-GetAllLiveBoutiqueStores', { params: { country } }).then(({ data }) => {
            return { Store: data.Store, PersonalizationStore: data.PersonalizationStore };
        });
    },
    searchStores: (from) => {
        return instance.get(`/StoresApi-FindAll`, { params: from }).then(({ data }) => {
            return data;
        });
    },
    searchStoresWithPersonalization: () => {
        return instance.get(`/StoresApi-StoresWithPersonalization`).then(({ data }) => data.stores);
    },
    searchStoreDetails: (storeId) => {
        return instance.get(`/StoresApi-StoreDetails`, { params: { storeId } }).then(({ data }) => {
            return data.store;
        });
    },
    countrySelectorHTML: () => {
        return instance.get('/Page-Locale', {
            params: {
                modal: true,
            },
        });
    },
    countrySelectorJson: () => {
        return instance.get('/Page-LocaleJson').then(({ data }) => {
            return data.data;
        });
    },
    languageSelectorJson: () => {
        return instance.get('/Page-LanguagesJson').then(({ data }) => {
            return data.data;
        });
    },
    getCustomerPaymentInstruments: async () => {
        return instance.get(`/AccountApi-PaymentInstruments`).then(({ data }) => {
            return data;
        });
    },
    getPreferredBoutique: async () => {
        return instance.get(`/AccountApi-PreferredBoutique`).then(({ data }) => {
            return data;
        });
    },
    deletePaymentMethod: async (uuid, id) => {
        return instance
            .get(`/PaymentInstruments-DeletePayment`, {
            params: {
                UUID: uuid,
                ID: id,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    searchFaqs: async (query) => {
        return instance.get('/ClientServiceApi-FaqSearch', { params: { query: query } }).then(({ data }) => {
            return data;
        });
    },
    getContactsByLocale: async (locale) => {
        return instance.get('/ClientServiceApi-GetContactsByLocale', { params: { locale: locale } }).then(({ data }) => {
            return data;
        });
    },
    getMonclerNowData: async (category, year, month, nextPage) => {
        return instance
            .get('/MonclerNow-List', {
            params: {
                category: category,
                year: year,
                month: month,
                p: nextPage,
            },
        })
            .then(({ data }) => data.results);
    },
    getMonclerNowRelated: async (id, number) => {
        return instance
            .get('/MonclerNow-Related', {
            params: {
                id: id,
                n: number,
            },
        })
            .then(({ data }) => data.results);
    },
    /**
     * Gets page data in JSON format from any Page Designer page given the respective ID
     *
     * @param pid page ID
     * @param native if true uses PageMgr.serializePage
     */
    getPageJson: async (pid, native) => {
        if (window.cachePageJson) {
            return api.getPageJsonCached(pid, native);
        }
        return instance
            .get(`/Api-Page`, {
            params: {
                pid,
                native,
            },
        })
            .then(({ data }) => {
            data.getFormattedData = function () {
                return api.extractRegions(this);
            };
            return data;
        });
    },
    extractRegions: (originalData) => {
        const data = Object.assign({}, originalData);
        if (data.regions) {
            data.regions.forEach((r) => {
                if (r.components) {
                    data[r.id] = r.components.map((c) => {
                        return api.extractRegions(c);
                    });
                }
            });
            delete data.regions;
        }
        return data;
    },
    getPageJsonCached: async (pid, native) => {
        window.pages = window.pages || {};
        let cacheKey = pid;
        if (native) {
            cacheKey = pid + '_native';
        }
        window.pages[cacheKey] = window.pages[cacheKey] || {};
        const editorialPageState = window.pages[cacheKey];
        if (editorialPageState.isLoaded) {
            return editorialPageState.data;
        }
        else if (editorialPageState.loading) {
            return new Promise((resolve) => {
                window.addEventListener(`page-${cacheKey}:json`, () => {
                    resolve(editorialPageState.data);
                }, { once: true });
            });
        }
        else {
            editorialPageState.loading = true;
            return instance
                .get(`/Api-Page`, {
                params: {
                    pid,
                    native,
                },
            })
                .then(({ data }) => {
                data.getFormattedData = function () {
                    return api.extractRegions(this);
                };
                editorialPageState.data = data;
                editorialPageState.loading = false;
                editorialPageState.isLoaded = true;
                window.dispatchEvent(new CustomEvent(`page-${cacheKey}:json`));
                return data;
            });
        }
    },
    allowTracking: async () => {
        return instance
            .get(`/ConsentTracking-SetSession`, {
            params: {
                consent: true,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    rejectTracking: async () => {
        return instance
            .get(`/ConsentTracking-SetSession`, {
            params: {
                consent: false,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    allowPersonalizedTracking: async (data) => {
        return instance.post(`/ConsentTracking-SetSessionCustomCookies`, data).then(({ data }) => {
            return data;
        });
    },
    CcpaSubmit: async (form, def) => {
        return instance.post(`/CcpaApi-Submit`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    isWebChatAvailable: async (IntegrationId, LanguageCode, ConversationId) => {
        return instance
            .post(window.environment == 'production'
            ? 'https://csim.industries-group.com/api/WebChat/IsWebChatAvailable'
            : 'https://csimtest.industries-group.com/api/WebChat/IsWebChatAvailable', qs.stringify({
            IntegrationId,
            LanguageCode,
            ConversationId,
        }), {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    GetSpecialSalesConfiguration: async (currentAppointmentKey) => {
        return instance
            .get('/Specialsales-GetSpecialSalesConfiguration', {
            params: {
                currentAppointmentKey: currentAppointmentKey,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    // LoginReservation: async (customerObj: models.CustomerReservation): Promise<models.CustomerReservation> => {
    //   return instance
    //     .post('/Reservation-SubmitReservation?isLogin=true', customerObj, {
    //       headers: {
    //         'content-type': 'application/json',
    //         accept: 'application/json',
    //       },
    //     })
    //     .then(({ data }) => {
    //       return data;
    //     });
    // },
    SubmitSpecialSaleBooking: async (employeeObj) => {
        return instance
            .post('/Specialsales-SubmitEmployee', employeeObj, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    GetSpecialSalePerson: async (uuid, currentAppointmentKey) => {
        return instance
            .get('/Specialsales-GetPerson', {
            params: {
                uuid: uuid,
                currentAppointmentKey: currentAppointmentKey,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    UpdateSpecialSaleEmployee: async (employeeObj) => {
        return instance
            .post('/Specialsales-UpdateEmployee', employeeObj, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    AddSpecialSaleGuests: async (guests, uuid) => {
        return instance
            .post('/Specialsales-AddGuests', {
            guests,
            uuid,
        }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    UpdateSpecialSaleGuest: async (guestObj) => {
        return instance
            .post('/Specialsales-UpdateGuest', guestObj, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    CheckSpecialSalePersonAlreadyRegistered: async (guests, uuid) => {
        return instance
            .post('/Specialsales-CheckGuestAlreadyRegistered', {
            guests,
            uuid,
        }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    ConfirmReservation: async (customerObj) => {
        return instance
            .post('/Reservation-ConfirmReservation', customerObj, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    isAlreadyRegistered: async (customerObj) => {
        return instance
            .post('/Reservation-isAlreadyRegistered', customerObj, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    getAppleSigninConfiguration: async () => {
        return instance.get('/AppleSignin-GetConfiguration').then(({ data }) => {
            return data;
        });
    },
    appleSignInSuccess: async (token, user, register) => {
        return instance
            .post('/AppleSignin-CaptureSignin', { token, user, register }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    getClothingMyAccountDetails: async () => {
        return instance.get('/ClothingApi-GetClothingMyAccountDetails').then(({ data }) => {
            return data;
        });
    },
    getNFTWallet: async () => {
        return instance.get('/Nft-GetWallet').then(({ data }) => {
            return data;
            //return chiamata;
        });
    },
    getOrderHystoryClothing: async (campaignID) => {
        return instance
            .get('/ClothingApi-OrderHistoryClothing', {
            params: {
                campaignID: campaignID,
            },
        })
            .then((resp) => resp.data.orders);
    },
    generateProofLink: async (certificateId) => {
        return instance
            .post('/Nft-GenerateProofLink', {
            certificateId: certificateId,
        }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            if (data.success) {
                return data.data;
            }
            throw data.error;
        });
    },
    viewMyNft: async (certificateId) => {
        return instance
            .post('/Nft-ViewMyCertificate', { certificateId }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    generateTransferLink: async (certificateId, target) => {
        return instance
            .post('/Nft-GenerateTransferLink', { certificateId, target }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    transferToEthereumWallet: async (certificateId, address) => {
        return instance
            .post('/Nft-TransferToEthereum', {
            certificateId,
            address,
        }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            if (data.success) {
                return data.data;
            }
        });
    },
    createClientService: async (clientServiceMap, clientServiceName, importedData = false) => {
        return instance
            .post('/BmClientService-SaveClientService', {
            clientServiceMap,
            clientServiceName,
            importedData,
        }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data.clientService;
        });
    },
    getAllClientServiceConfiguration: async () => {
        return instance.get('/BmClientService-GetAllClientServiceConfiguration').then(({ data }) => {
            return data.clientService;
        });
    },
    deleteClientService: async (language, countryCode, clientServiceName) => {
        return instance
            .post('/BmClientService-DeleteClientService', {
            language,
            countryCode,
            clientServiceName,
        }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data.clientService;
        });
    },
    checkGeolocationCountryMatch: async (currentCountryCode) => {
        return instance
            .get('/Api-CheckGeolocationCountryMatch', {
            params: {
                currentCountryCode: currentCountryCode,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    setSessionAttribute: async (attribute, attributeValue) => {
        // @TODO @EARLYACCESS fix below, fix promise model;
        return instance
            .post('/AccountApi-SetSessionAttribute', { name: attribute, value: attributeValue }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    setCustomerAttribute: async (attribute, attributeValue) => {
        // @TODO @EARLYACCESS fix below, fix promise model
        return instance
            .post('/AccountApi-SetCustomerAttribute', { attribute, attributeValue }, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    setRetargetingFlagInBasket: async (params) => {
        // @TODO @EARLYACCESS fix below, fix promise model
        return instance
            .post('/AccountApi-SetRetargetingFlagInBasket', params, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    getPromotionInfo: async () => {
        return instance.get('/AccountApi-GetPromotionInfo').then(({ data }) => {
            return data;
        });
    },
    getHongKongLocalityCodesByArea: (areaCode) => {
        return instance
            .get(`/Address-HongKongLocalityCodes`, {
            params: {
                area: areaCode,
            },
        })
            .then(({ data }) => {
            return data.localityCodes;
        });
    },
    checkGiftCardBalance: async (form, def) => {
        return instance.post('/GiftCard-CheckBalance', qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    gcSubmitBillingAddress: async (form, def) => {
        return instance.post(`/GiftCard-SubmitBillingAddress`, qs.stringify(await withCsrf(formutils.objToForm(form, def)))).then(({ data }) => {
            return data;
        });
    },
    gcEditLineItem: async (form, def) => {
        return instance.post('GiftCard-EditLineItem', { params: qs.stringify(await withCsrf(formutils.objToForm(form, def))) }).then(({ data }) => {
            return data;
        });
    },
    gcEditWishlistItem: async (form, def) => {
        return instance.post('GiftCard-EditWishlistLineItem', { params: qs.stringify(await withCsrf(formutils.objToForm(form, def))) }).then(({ data }) => {
            return data;
        });
    },
    adyenGcBalanceCheck: async (adyenObject) => {
        return instance
            .post('/Adyen-CheckBalance', adyenObject, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    adyenGcOrderRequest: async (adyenObject) => {
        return instance
            .post('/Adyen-PartialPaymentsOrder', adyenObject, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    adyenGcPartialPayment: async (adyenObject) => {
        return instance
            .post('/Adyen-partialPayment', adyenObject, {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    gcCheckoutApply: async (dataForm) => {
        return instance
            .get('/GiftCard-Apply', {
            params: dataForm,
        })
            .then(({ data }) => {
            return data;
        });
    },
    gcCheckoutRemove: async (cardNumber, orderID, orderToken) => {
        return instance
            .get('/GiftCard-Remove', {
            params: {
                cardNumber: cardNumber,
                orderID,
                orderToken,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
    gcEnableDisable: async (value, orderID, orderToken) => {
        return instance
            .get('/GiftCard-EnableDisable', {
            params: {
                orderID,
                orderToken,
                value,
            },
        })
            .then(({ data }) => {
            return data;
        });
    },
};
export default api;
