const root = document.documentElement;
const body = document.querySelector('body');
const maincontent = document.getElementById('maincontent');
const footer = document.querySelector('.footer');
const header = document.querySelector('header');
const isCartOrCheckout = ['cart-show'].includes(body.id) || body.classList.contains('checkout'); // TO CHECK: Useless, checking footer is enough?
let screenWidth;
const addMarginBottomForFooter = () => {
    if (window.innerWidth < window.mqObj.md || footer.offsetHeight >= window.innerHeight - header.offsetHeight) {
        root.classList.remove('fixed-footer');
    }
    else if (window.innerWidth >= window.mqObj.md) {
        root.classList.add('fixed-footer');
        root.style.setProperty('--footer-margin', footer.clientHeight + 'px');
    }
};
const widthObserver = new ResizeObserver((entries) => {
    var _a;
    const newScreenWidth = ((_a = entries[0]) === null || _a === void 0 ? void 0 : _a.target).offsetWidth;
    if (newScreenWidth !== screenWidth) {
        addMarginBottomForFooter();
        screenWidth = newScreenWidth;
    }
});
export const addMarginBottomForFooterInit = () => {
    if (footer && maincontent && !isCartOrCheckout && !window.appmode) {
        screenWidth = footer.offsetWidth;
        new IntersectionObserver((entries) => {
            if (entries) {
                addMarginBottomForFooter;
                if (entries[0].isIntersecting) {
                    widthObserver.observe(footer);
                }
                else {
                    widthObserver.disconnect();
                }
            }
        }, { rootMargin: '40px' }).observe(footer);
    }
};
