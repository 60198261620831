import BaseService from '../base/baseService';
export default class OverlayService extends BaseService {
    constructor() {
        super();
        this.status = false;
        this.$el = document.body;
        this.name = 'OverlayService';
    }
    get CLASSES() {
        return {
            active: 'overlay',
        };
    }
    get Messages() {
        return {
            [this.CUSTOM_MESSAGES.BODY.TOGGLE_OVERLAY]: this.toggleOverlay,
            [this.CUSTOM_MESSAGES.BODY.ON_OVERLAY]: this.activateOverlay,
            [this.CUSTOM_MESSAGES.BODY.OFF_OVERLAY]: this.deactivateOverlay,
        };
    }
    toggleOverlay() {
        this.status = !this.status;
        // this.$log(this.name, this.status ? 'on' : 'off');
        if (this.status) {
            this.$el.classList.add(this.CLASSES.active);
        }
        else {
            this.$el.classList.remove(this.CLASSES.active);
        }
    }
    activateOverlay() {
        if (this.status) {
            return;
        }
        this.status = true;
        // this.$log(this.name, 'on');
        this.$el.classList.add(this.CLASSES.active);
    }
    deactivateOverlay() {
        if (!this.status) {
            return;
        }
        this.status = false;
        // this.$log(this.name, 'off');
        this.$el.classList.remove(this.CLASSES.active);
    }
}
