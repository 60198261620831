import api from '@utils/api';
const formutils = {
    flattenForm: (obj) => {
        if (!obj) {
            return obj;
        }
        let groupvalid = obj.valid;
        let grouperror = obj.error;
        Object.keys(obj).forEach((o) => {
            const sub = obj[o];
            // copia l'errore del gruppo sul primo elemento contenuto (se non c'è già un errore specifico)
            if (sub && typeof sub !== 'string' && sub.formType === 'formField' && !groupvalid && grouperror && !sub.error) {
                sub.valid = groupvalid;
                sub.error = grouperror;
                groupvalid = true;
                grouperror = null;
            }
            if (sub && typeof sub !== 'string' && sub.formType === 'formGroup') {
                obj[o] = undefined;
                obj = Object.assign(Object.assign({}, obj), formutils.flattenForm(sub));
            }
        });
        return obj;
    },
    fillFirstValueFromSelectIfEmpty: function (form, initialValues, key) {
        if (!form || !initialValues || !!initialValues[key] || !form[key] || !form[key].options || form[key].options.length === 0) {
            return;
        }
        let opt = form[key].options[0];
        opt.selected = true;
        opt.checked = true;
        initialValues[key] = opt.htmlValue;
    },
    fillInitialValues: function (form, initialValues) {
        if (!form) {
            return;
        }
        Object.keys(form)
            .filter((f) => form[f] && typeof form[f] !== 'string' && form[f].formType)
            .forEach((f) => {
            const fd = form[f];
            initialValues[f] = fd.htmlValue;
            if (fd.checked === false) {
                initialValues[f] = undefined;
            }
            else if (fd.checked === true) {
                initialValues[f] = true;
            }
            if (fd.options && !fd.htmlValue) {
                fd.options
                    .filter((o) => o.selected || o.checked)
                    .forEach((o) => {
                    o.selected = true;
                    o.checked = true;
                    initialValues[f] = o.htmlValue;
                });
            }
        });
    },
    validateServerInternal: async function (values, def, formname) {
        const errors = {};
        let servervalidation = await api.validateForm(values, def, formname).catch((e) => {
            console.error(e);
            return {};
        });
        //  console.log('servervalidation: ' + JSON.stringify(servervalidation, null, 2));
        if (servervalidation) {
            Object.keys(servervalidation)
                .filter((f) => servervalidation[f] && typeof servervalidation[f] !== 'string' && servervalidation[f].error)
                .forEach((f) => {
                errors[f] = servervalidation[f].error;
            });
        }
        return errors;
    },
    labelByValue: (value, field) => {
        var _a, _b, _c;
        if (value === '') {
            return '';
        }
        return (_c = (_b = (_a = field === null || field === void 0 ? void 0 : field.options) === null || _a === void 0 ? void 0 : _a.find((t) => t.value === value)) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : '';
    },
    objToForm: function (values, def) {
        let result = {};
        let keys = new Set(Object.keys(def).concat(Object.keys(values)));
        keys.forEach((f) => {
            const fd = def[f];
            const value = values[f];
            if (fd && fd.dynamicHtmlName) {
                result[fd.dynamicHtmlName] = value || '';
            }
            else {
                if (value != undefined)
                    result[f] = value;
            }
        });
        return result;
    },
    validateForm: async function (values, formdef, formname) {
        return await formutils.validateServerInternal(values, formdef, formname);
    },
    mergeErrors: function (data, errorcontainer, errorfromapi) {
        if (data === null || data === void 0 ? void 0 : data[errorcontainer]) {
            if (data[errorcontainer].length) {
                data[errorcontainer].forEach((fe) => {
                    errorfromapi = Object.assign(Object.assign({}, errorfromapi), fe);
                });
            }
            else {
                errorfromapi = Object.assign(Object.assign({}, errorfromapi), data[errorcontainer]);
            }
        }
        return errorfromapi;
    },
    globalMutator: function ([selected], state, { changeValue }) {
        Object.keys(selected).forEach((k) => {
            changeValue(state, k, () => selected[k]);
        });
    },
    checkValidationAfterSubmit(data, errors) {
        let errorfromapi = {};
        // a volte in fields, a volte in fieldErrors, a volte oggetti, a volte array...
        errorfromapi = this.mergeErrors(data, 'fieldErrors', errorfromapi);
        errorfromapi = this.mergeErrors(data, 'fields', errorfromapi);
        if (Object.keys(errorfromapi).length > 0) {
            Object.keys(errorfromapi).forEach((f) => {
                errors[f.substring(f.lastIndexOf('_') + 1, f.length)] = data.fields && data.fields[f];
            });
        }
        return Object.keys(errors).length === 0;
    },
    checkHoursFormat: (rows) => {
        let isHoursValid = {
            msg: '',
            valid: true,
        };
        rows.forEach((htmlInput, idx) => {
            let inputs = htmlInput.getElementsByTagName('input');
            let daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            let regexHours = new RegExp(/"?(0?[0-9]|1[0-2]):[0-5][0-9] (am|pm) - (0?[0-9]|1[0-2]):([0-5][\d]) (am|pm)"?/);
            Object.values(inputs).forEach((input) => {
                if (daysOfWeek.includes(input.name)) {
                    let innerValues = input.value.split(',');
                    let idx = 0;
                    while (idx < innerValues.length && isHoursValid.valid) {
                        if (!regexHours.test(innerValues[idx])) {
                            isHoursValid.valid = false;
                            isHoursValid.msg = input.name + ' field is invalid, please provide a correct format hour e.g 09:00 am - 06:00 pm';
                            return;
                        }
                    }
                }
            });
            if (!isHoursValid.valid) {
                return isHoursValid;
            }
        });
        return isHoursValid;
    },
    checkRequiredFields: (rows) => {
        let result = true;
        rows.forEach((htmlInput, idx) => {
            let inputs = htmlInput.getElementsByTagName('input');
            Object.values(inputs).forEach((input) => {
                if (input.name === 'country' || input.name === 'countryCode' || input.name === 'language') {
                    if (!input.value) {
                        result = false;
                    }
                }
            });
        });
        return result;
    },
};
export default formutils;
