import deepFreeze from '../utilities/freeze';
export default deepFreeze({
    BODY: {
        TOGGLE_OVERLAY: 'toggle:overlay',
        ON_OVERLAY: 'on:overlay',
        OFF_OVERLAY: 'off:overlay',
    },
    HEADER: {
        // IS_TRANSPARENT: 'header:isTransparent',
        IN_PDP: 'header:inPdp',
    },
    BREAKPOINTER: {
        BREAKPOINT_CHANGE: 'breakpoint:change',
    },
});
