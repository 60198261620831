import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
const map = new Map();
const markinitialized = (el) => {
    el.classList.remove('uninitialized');
    el.classList.remove('initializing');
    el.classList.add('initialized');
    return true;
};
export const registerComponent = (name, render, withRecoil) => {
    map.set(name, {
        component: (attrs, el) => (React.createElement(Suspense, { fallback: React.createElement("div", { className: "tmploader", dangerouslySetInnerHTML: { __html: el.innerHTML } }) }, withRecoil ? React.createElement(RecoilRoot, null, markinitialized(el) && render(attrs)) : markinitialized(el) && render(attrs))),
    });
};
export const registerDefaultComponents = () => {
    const Express = React.lazy(() => import(/* webpackChunkName: "component-checkoutexpress" */ './components/checkout/ExpressLoader'));
    registerComponent('checkoutexpress-app', (attrs) => React.createElement(Express, Object.assign({}, attrs)));
    const Chat = React.lazy(() => import(/* webpackChunkName: "component-chat" */ './components/chat/Chat'));
    registerComponent('chat', (attrs) => React.createElement(Chat, Object.assign({}, attrs)));
    const CheckoutPayByLink = React.lazy(() => import(/* webpackChunkName: "component-checkoutpaybylink" */ './components/checkout/CheckoutPayByLink'));
    registerComponent('checkoutpaybylink-app', (attrs) => React.createElement(CheckoutPayByLink, Object.assign({}, attrs)));
    const CheckoutPayByLinkInvalid = React.lazy(() => import(/* webpackChunkName: "component-checkoutpaybylink" */ './components/checkout/CheckoutPayByLinkInvalid'));
    registerComponent('checkoutpaybylink-invalid', (attrs) => React.createElement(CheckoutPayByLinkInvalid, Object.assign({}, attrs)));
    const Checkout = React.lazy(() => import(/* webpackChunkName: "component-checkout" */ './components/checkout/Checkout'));
    registerComponent('checkout-app', (attrs) => React.createElement(Checkout, Object.assign({}, attrs)));
    const AccountSetupPassword = React.lazy(() => import(/* webpackChunkName: "component-forgotpassword-step2" */ './components/account/AccountSetupPasswordLoader'));
    registerComponent('setuppassword-app', (attrs) => React.createElement(AccountSetupPassword, Object.assign({}, attrs)));
    const FormTester = React.lazy(() => import(/* webpackChunkName: "component-formtester" */ './components/common/FormTester'));
    registerComponent('formtester-app', (attrs) => React.createElement(FormTester, Object.assign({}, attrs)));
    const CsTool = React.lazy(() => import(/* webpackChunkName: "component-clientservicelanding" */ './components/businessmanager/CsTool'));
    registerComponent('cs-tool', (attrs) => React.createElement(CsTool, Object.assign({}, attrs)));
    const CorporateModal = React.lazy(() => import(/* webpackChunkName: "component-corporateinfo" */ './components/common/CorporateModal'));
    registerComponent('corporateinfo', (attrs) => React.createElement(CorporateModal, Object.assign({}, attrs)));
    const CookieCenter = React.lazy(() => import(/* webpackChunkName: "component-cookiecenter" */ './components/common/CookieCenter'));
    registerComponent('cookie-center', (attrs) => React.createElement(CookieCenter, Object.assign({}, attrs)));
    const CookieConsent = React.lazy(() => import(/* webpackChunkName: "component-cookieconsent" */ './components/common/CookieConsent'));
    registerComponent('cookie-consent', (attrs) => React.createElement(CookieConsent, Object.assign({}, attrs)));
    const OrderDetailPageLoader = React.lazy(() => import(/* webpackChunkName: "component-orderdetailhistory" */ './components/order/OrderDetailPageLoader'));
    registerComponent('orderdetail', (attrs) => React.createElement(OrderDetailPageLoader, Object.assign({}, attrs)));
    const AftercareDetailPageLoader = React.lazy(() => import(/* webpackChunkName: "component-aftercaredetailhistory" */ './components/order/AftercareDetailPageLoader'));
    registerComponent('aftercaredetail', (attrs) => React.createElement(AftercareDetailPageLoader, Object.assign({}, attrs)));
    const ReturnInBoutique = React.lazy(() => import(/* webpackChunkName: "component-returninboutique" */ './components/returns/ReturnInBoutique'));
    registerComponent('returninboutique', (attrs) => React.createElement(ReturnInBoutique, Object.assign({}, attrs)));
    const Ccpa = React.lazy(() => import(/* webpackChunkName: "component-ccpa" */ './components/ccpa/ccpa'));
    registerComponent('ccpa', (attrs) => React.createElement(Ccpa, Object.assign({}, attrs)));
    const YamatoTester = React.lazy(() => import(/* webpackChunkName: "component-yamatotester" */ './components/businessmanager/YamatoTester'));
    registerComponent('yamatotester', (attrs) => React.createElement(YamatoTester, Object.assign({}, attrs)));
    const PasswordChanged = React.lazy(() => import(/* webpackChunkName: "component-passwordchanged" */ './components/account/PasswordChanged'));
    registerComponent('passwordchanged-app', (attrs) => React.createElement(PasswordChanged, Object.assign({}, attrs)));
    const AppCreditcard = React.lazy(() => import(/* webpackChunkName: "component-appcreditcard" */ './components/account/AppCreditCardLoader'));
    registerComponent('appcreditcard', (attrs) => React.createElement(AppCreditcard, Object.assign({}, attrs)));
    const MbmCapacity = React.lazy(() => import(/* webpackChunkName: "mbm-capacity" */ './components/businessmanager/MbmCapacity'));
    registerComponent('mbm-capacity', (attrs) => React.createElement(MbmCapacity, Object.assign({}, attrs)));
    const ClothingCampaign = React.lazy(() => import(/* webpackChunkName: "clothing-campaign" */ './components/businessmanager/ClothingCampaign'));
    registerComponent('clothing-campaign', (attrs) => React.createElement(ClothingCampaign, Object.assign({}, attrs)));
    const Transligrade = React.lazy(() => import(/* webpackChunkName: "transligrade" */ './components/translator/Transligrade'));
    registerComponent('transligrade', (attrs) => React.createElement(Transligrade, Object.assign({}, attrs)));
    const DevServerToggler = React.lazy(() => import(/* webpackChunkName: "component-devservertoggler" */ './components/devtools/DevServerToggler'));
    registerComponent('devservertoggler', (attrs) => React.createElement(DevServerToggler, Object.assign({}, attrs)));
    const RegisteredInStoreCheckoutConfirm = React.lazy(() => import(/* webpackChunkName: "component-forgotpassword-step2" */ './components/login/RegisteredInStoreCheckoutConfirm'));
    registerComponent('registeredinstore-app', (attrs) => React.createElement(RegisteredInStoreCheckoutConfirm, Object.assign({}, attrs)));
    const StoreLocatorApp = React.lazy(() => import(/* webpackChunkName: "component-storelocator-app" */ '@common/components/storelocator/StoreLocatorApp'));
    registerComponent('storelocator-app', (attrs) => React.createElement(StoreLocatorApp, Object.assign({}, attrs)));
    const EventBusWrapper = React.lazy(() => import(/* webpackChunkName: "component-eventbuswrapper" */ './utils/EventBusWrapper'));
    registerComponent('eventbus-wrapper', (attrs) => React.createElement(EventBusWrapper, Object.assign({}, attrs)));
};
// * REGISTER CORE COMPONENTS ---------------------------------------------------------------------------------------------
export const initReactApps = () => {
    const els = document.querySelectorAll(`[data-react]`);
    els.forEach((el) => {
        let reactid = el.dataset['react'];
        const dataAttrs = JSON.parse(JSON.stringify(el.dataset));
        // gestisci valori booleani
        Object.keys(dataAttrs).forEach((k) => {
            if (dataAttrs[k] === 'true' || dataAttrs[k] === 'false') {
                dataAttrs[k] = dataAttrs[k] === 'true';
            }
            else if (dataAttrs[k] === 'null') {
                dataAttrs[k] = null;
            }
        });
        let val = map.get(reactid);
        if (!val) {
            console.log('Cannot find react component ' + reactid);
            return;
        }
        el.classList.add('initializing');
        dataAttrs.placeholder = el.innerHTML;
        let comp = val.component(dataAttrs, el);
        // linking dom elements -> react roots
        ReactDOM.render(comp, el);
    });
};
