import config from 'gia/config';
import CustomMessages from '../constants/customMessages';
import EventBus from '../libs/eventBus';
import { getAllFocusableElements, getFirstFocusable } from '../utilities/focusableElementUtils';
export default class CommonInterface {
    constructor() {
        config.set('log', true);
        this._grabMessages();
    }
    get CUSTOM_MESSAGES() {
        return CustomMessages;
    }
    get Messages() {
        return {};
    }
    _checkMessages() {
        for (const message in this.Messages) {
            if (window.eventQueue.get(message)) {
                const ref = this.Messages[message].bind(this);
                window.eventQueue.get(message).map((payload) => {
                    ref(payload);
                });
                window.eventQueue.delete(message);
            }
        }
    }
    _grabMessages() {
        this._messages = {};
        for (const message in this.Messages) {
            if (this.Messages[message]) {
                const __ref__ = this.Messages[message].bind(this);
                this._messages[message] = __ref__;
                this.GRAB(message, __ref__);
            }
        }
    }
    _ungrabMessages() {
        for (const message in this._messages) {
            this.UNGRAB(message, this._messages[message]);
        }
    }
    objectIsEmpty(object) {
        for (let key in object) {
            if (object.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    /**
     *
     * EMIT
     *
     * @param {string} message
     * @param {Object} payload
     */
    EMIT(message, payload) {
        return EventBus.emit(message, payload);
    }
    /**
     *
     * GRAB
     *
     * @param {string} message
     * @param {Function} callBack
     */
    GRAB(message, callBack) {
        if (!callBack)
            return;
        EventBus.on(message, callBack);
    }
    /**
     *
     * UNGRAB
     *
     * @param {string} message
     * @param {Function} callBack
     */
    UNGRAB(message, callBack) {
        if (!callBack)
            return;
        EventBus.off(message, callBack);
    }
    getFirstFocusable(parent) {
        return getFirstFocusable(parent);
    }
    getFocusableElements(parent) {
        return getAllFocusableElements(parent);
    }
    _destroy() {
        this._ungrabMessages();
    }
}
