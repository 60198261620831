import { debounce } from '@utils/functions';
import BaseService from '../base/baseService';
export const applyScrollbarWidth = () => {
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    if (scrollBarWidth > 0) {
        document.body.style.setProperty('--scrollbar-width', scrollBarWidth + 'px');
    }
    else {
        document.body.style.removeProperty('--scrollbar-width');
    }
};
export default class BreakpointerService extends BaseService {
    constructor() {
        super();
        // handle breakpoint from 0 to ∞
        this.mediaQueries = Object.assign({ '<xs': 0 }, window.mqObj);
        // this.currentBreakpoint = Object.keys(this.mediaQueries)[0];
        const debounceResize = debounce(this.checkBreakpoint.bind(this), 300);
        window.addEventListener('resize', () => {
            debounceResize();
        }, true);
        this.checkBreakpoint();
        // check scrollbar width to avoid reflow on modals opening
        if (['complete', 'interactive'].includes(document.readyState)) {
            applyScrollbarWidth();
        }
        else {
            let completedInterval = setInterval(() => {
                if (['complete', 'interactive'].includes(document.readyState)) {
                    // The page is fully loaded.
                    applyScrollbarWidth();
                    clearInterval(completedInterval);
                    completedInterval = null;
                }
                else {
                    console.info('waiting for document.readyState completed');
                }
            }, 500);
        }
    }
    getCurrentBreakpoint() {
        const documentWidth = window.innerWidth;
        if (documentWidth == 0) {
            return null;
        }
        const filtered = Object.keys(this.mediaQueries).filter((key) => this.mediaQueries[key] <= documentWidth);
        this.currentBreakpoint = filtered[filtered.length - 1];
        return this.currentBreakpoint;
    }
    checkBreakpoint() {
        this.getCurrentBreakpoint();
        if (this.lastBreakpoint && this.lastBreakpoint !== this.currentBreakpoint) {
            applyScrollbarWidth();
            console.info('%c[breakpointerService]', 'color: green;font-weight: bold;', this.currentBreakpoint, this.mediaQueries[this.currentBreakpoint]);
            this.EMIT(this.CUSTOM_MESSAGES.BREAKPOINTER.BREAKPOINT_CHANGE, {
                breakpoint: this.currentBreakpoint,
                value: this.mediaQueries[this.currentBreakpoint],
            });
        }
        this.lastBreakpoint = this.currentBreakpoint;
        return this.currentBreakpoint;
    }
}
