export const debounce = (callback, waitFor) => {
    let timeout;
    return (...args) => {
        let result;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            result = callback(...args);
        }, waitFor);
        return result;
    };
};
//initialize throttlePause variable outside throttle function
let throttlePause;
export const throttle = (callback, waitFor) => {
    let timeout;
    return (...args) => {
        let result;
        if (throttlePause)
            return;
        clearTimeout(timeout);
        throttlePause = true;
        timeout = setTimeout(() => {
            result = callback(...args);
            throttlePause = false;
        }, waitFor);
        return result;
    };
};
export const checkMobile = () => {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i];
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};
