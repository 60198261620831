/**
 * Sends a message to the app's React Native WebView.
 *
 * It uses different channels for the default and the WebKit WebView.
 *
 * @link https://github.com/react-native-webview/react-native-webview/releases/tag/v5.0.0
 * @link https://github.com/react-native-webview/react-native-webview/pull/1946
 * @param message - The message to send
 * @returns true if the message was sent
 */
export function appPostMessage(message) {
    var _a, _b;
    if (!window.appmode)
        return false;
    // @ts-ignore
    if (Boolean((_b = (_a = window.webkit) === null || _a === void 0 ? void 0 : _a.messageHandlers) === null || _b === void 0 ? void 0 : _b.ReactNativeWebView)) {
        // Sending the message to a WebKit WebView
        // @ts-ignore
        window.webkit.messageHandlers.ReactNativeWebView.postMessage(message, '*');
        return true;
        // @ts-ignore
    }
    else if (Boolean(window.ReactNativeWebView)) {
        // Sending the message to a standard WebView
        // @ts-ignore
        window.ReactNativeWebView.postMessage(message);
        return true;
    }
    return false;
}
/**
 * Sends a structured message to the app's React Native WebView.
 *
 * A structured message is composed of a type and an optional object payload.
 * It uses different channels for the default and the WebKit WebView.
 *
 * @link https://github.com/react-native-webview/react-native-webview/releases/tag/v5.0.0
 * @link https://github.com/react-native-webview/react-native-webview/pull/1946
 * @param type - The type of message to send
 * @param data - The optional payload to send
 * @returns true if the message was sent
 */
export function appPostStructuredMessage(type, data) {
    var _a, _b;
    if (!window.appmode)
        return false;
    const payload = JSON.stringify({
        type,
        data,
    });
    // @ts-ignore
    if (Boolean((_b = (_a = window.webkit) === null || _a === void 0 ? void 0 : _a.messageHandlers) === null || _b === void 0 ? void 0 : _b.ReactNativeWebView)) {
        // Sending the payload to a WebKit WebView
        // @ts-ignore
        window.webkit.messageHandlers.ReactNativeWebView.postMessage(payload, '*');
        return true;
        // @ts-ignore
    }
    else if (Boolean(window.ReactNativeWebView)) {
        // Sending the payload to a standard WebView
        // @ts-ignore
        window.ReactNativeWebView.postMessage(payload);
        return true;
    }
    return false;
}
